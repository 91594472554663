<template>
  <div>
      <v-alert
        border="left"
        color="red"
        elevation="5"
        type="error"
      >
        {{ $t('errors.page-not-found') }}
      </v-alert>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  created: () => {}
}
</script>
